import React from 'react';

import {
  Participant,
  ParticipantStep,
  ParticipantStepState,
} from '@wix/ambassador-challenges-v1-participant/types';

import {
  isQuizFailed,
  isStepAvailableForComplete,
  isStepOverdue,
  isStepPending,
  isStepRunning,
} from '../../components/ParticipantPage/Widget/views/utils';

import { CompletedIcon } from '../CompletedIcon';
import { LockedIcon } from '../LockedIcon';
import { RunningIcon } from '../RunningIcon';
import StepArticle from '../../assets/icons/step-article.svg';
import StepVideo from '../../assets/icons/step-video.svg';
import StepQuiz from '../../assets/icons/step-quiz.svg';
import Missed from '../../assets/icons/missed2.svg';
import CloseIcon from '../../assets/icons/circle-close.svg';
import LockedIconQuiz from '../../assets/icons/locked-fill.svg';
import { FCWithChildren } from '@wix/challenges-web-library';
import { isUserCompleted } from '../../contexts/User/helpers/userTypeHandlers';
import { getState } from '../../selectors/participant';
import { getStepType } from '../../selectors/step';

export interface IStepIcon {
  step: ParticipantStep;
  isUnavailable: boolean;
  participant: Participant;
  isParticipantInNegativeState: boolean;
}

const STEP_INDICATOR = {
  [ParticipantStepState.COMPLETED]: <CompletedIcon />,
  [ParticipantStepState.OVERDUE]: <Missed />,
  [ParticipantStepState.PENDING]: <LockedIcon />,
  [ParticipantStepState.RUNNING]: <RunningIcon />,
  STEP_COMPLETION_DISABLED: <LockedIcon />,
  LOCKED_ICON: <LockedIconQuiz />,
  CLOSE_ICON: <CloseIcon />,
};

const STEP_TYPE_ICONS = {
  article: <StepArticle />,
  video: <StepVideo />,
  quiz: <StepQuiz />,
};

export const StepIcon: FCWithChildren<IStepIcon> = (props) => {
  const { step, participant, isUnavailable } = props;
  const stepType = getStepType(step?.source);

  if (isQuizFailed(step)) {
    return STEP_INDICATOR.CLOSE_ICON;
  } else if (props.isParticipantInNegativeState) {
    return STEP_INDICATOR.LOCKED_ICON;
  }

  if (
    (isStepOverdue(step) || isStepRunning(step) || isStepPending(step)) &&
    isUserCompleted(getState(participant))
  ) {
    return STEP_INDICATOR.STEP_COMPLETION_DISABLED;
  }

  if (isUnavailable) {
    return STEP_INDICATOR[ParticipantStepState.PENDING];
  }

  if (isStepAvailableForComplete(step)) {
    return stepType
      ? STEP_TYPE_ICONS[stepType.toLowerCase()]
      : STEP_INDICATOR[ParticipantStepState.RUNNING];
  }

  return STEP_INDICATOR[step.transitions[0].state];
};
