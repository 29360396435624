import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import { useSettings } from '@wix/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { useIsStepHidden } from '../../../../../contexts/IsStepHidden/IsStepHiddenContext';
import { useUser } from '../../../../../contexts/User/UserContext';
import utils, {
  isStepAvailableForComplete,
  isQuizFailed,
} from '../../views/utils';

import challengeSettings from '../../../settingsParams';
import { StepIcon } from '../../../../../components-shared/StepIcon';
import { useMonitoring } from '../../../../../contexts/Monitoring/MonitoringContext';
import { FedopsInteraction } from '../../../../../config/fedopsInteraction';
import TextWithDirection from '../../../../../components-shared/TextWithDirection';

import { FCWithChildren } from '@wix/challenges-web-library';
import { isUserCompleted } from '../../../../../contexts/User/helpers/userTypeHandlers';
import { getTitle } from '../../../../../selectors/step';
import { useVideoStepData } from '../../../../../hooks/useVideoStepData';
import { formatProgramVideoDuration } from '../VideoPlayerInStep/formatDuration';

import { st, classes } from './StepsListAsTiles.st.css';

export interface IStepSidebar {
  step: ParticipantStep;
  isSPC: boolean;
  currentStepId: string;
  onStepChosen: Function;
  className?: string;
}

export const StepSidebar: FCWithChildren<IStepSidebar> = (props) => {
  const { t } = useTranslation();
  const { isSPC, currentStepId, onStepChosen, step } = props;
  const { isStepHidden, isVisibleStepLockedForComplete } = useIsStepHidden();
  const { participant, participantV3 } = useUser();
  const settings = useSettings();
  const { startInteraction } = useMonitoring();
  const { isParticipantInSuspendedState, isParticipantInLockedState } =
    useUser();
  const isParticipantInNegativeState =
    isParticipantInSuspendedState || isParticipantInLockedState;
  const isParticipantCompletedChallengeState = isUserCompleted(
    participant?.transitions?.[0]?.state,
  );

  const isStepUnavailable =
    isStepHidden(step) || isVisibleStepLockedForComplete(step);
  const tooltipContent = t(
    utils.getStepTooltipKey(step, isStepUnavailable, isSPC, participant),
  );
  const tooltipDisabled =
    !isUserCompleted(participant?.transitions?.[0]?.state) &&
    isStepAvailableForComplete(step) &&
    !isStepUnavailable;
  const isCurrent = step?.id === currentStepId;

  const { videoStepDuration } = useVideoStepData(step?.source);

  return (
    <li
      className={st(classes.stepItem, {}, isCurrent && classes.stepItemActive)}
      data-id={step?.id}
      key={step?.id}
      onClick={() => {
        startInteraction?.(FedopsInteraction.ViewStepAtSidebarLayout, step?.id);
        onStepChosen(step);
      }}
    >
      <button
        className={st(classes.stepLink, {
          align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
        })}
        onClick={() => {}}
      >
        <div className={classes.stepItemIcon}>
          {tooltipDisabled ? null : (
            <div className={classes.srOnly}>{tooltipContent}</div>
          )}
          <StepIcon
            step={step}
            isUnavailable={isStepUnavailable}
            participant={participantV3 || participant}
            isParticipantInNegativeState={isParticipantInNegativeState}
          />
        </div>
        <div className={classes.gapBetweenTitleAndIcon} />

        <TextWithDirection>
          <p className={classes.stepItemTitle}>{getTitle(step?.source)}</p>
        </TextWithDirection>

        {videoStepDuration &&
        !isParticipantInNegativeState &&
        !isParticipantCompletedChallengeState ? (
          <TextWithDirection>
            <p className={classes.stepItemSubtitle}>
              {/* t(`live-site.step-type-subtitle.${stepType.toLowerCase()}`) removed for now */}
              {videoStepDuration
                ? formatProgramVideoDuration(t, videoStepDuration)
                : ''}
            </p>
          </TextWithDirection>
        ) : null}

        {isQuizFailed(step) ? (
          <TextWithDirection>
            <p className={classes.stepItemSubtitle}>
              {t('live-site.step-quiz.step-failed-subtitle')}
            </p>
          </TextWithDirection>
        ) : null}
      </button>
    </li>
  );
};

StepSidebar.displayName = 'StepSidebarItem';
